import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { MenuItem, User, ViewerToolbarTool } from 'src/core/index';

import { ConfigurationService, RoutingService, ViewerService } from 'src/core/index/services.index';
import { FaqsListComponent, ViewerCourseDetailComponent } from 'src/index';
import { AuthenticationService, LoadingService } from 'src/index/services.index';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationServiceCustom extends ConfigurationService {
    constructor(
        authService: AuthenticationService<User>,
        loadingService: LoadingService,
        routingService: RoutingService,
        viewerService: ViewerService,
    ) {
        super(authService, loadingService, routingService, viewerService);

        this.registrationStepFields[0].push(
            { id: 'degree', label: 'Degree', inputType: 'text', editable: true, additionalData: true, required: true, validators: Validators.required },
            { id: 'institution', label: 'Belonging institution or university', inputType: 'text', editable: true, additionalData: true, required: true, validators: Validators.required },
            { id: 'department', label: 'Department/Course of study', inputType: 'text', editable: true, additionalData: true, required: true, validators: Validators.required },
        );
        this.registrationStepFields[1] = [];

        this.fixedSearchFilters = [{
            id: 'type',
            type: 'checkbox',
            values: [
                { value: 'course', label: 'Courses' },
            ],
            selectedValue: ['course'],
            showInSimpleSearch: false,
        }, {
            id: 'field',
            type: 'radio',
            values: [
                { value: 'title', label: 'Search in the title' },
                { value: 'contents', label: 'Search the contents' },
            ],
            selectedValue: 'title',
            showInSimpleSearch: false,
        }];
        this.simpleSearchSettings = this.fixedSearchFilters.filter(filters => filters.showInSimpleSearch)
            .reduce((x, y) => [...x, ...y.values.map(item => ({ ...item, group: y.id }))], [] as Array<{ value: string; label: string; group: string }>);
    }

    override getDashboardMenuItems(): MenuItem[] {
        return [
            this.appSections.dashboard,
            this.appSections.myCourses,
            this.appSections.search,
            {
                id: 'faqs',
                label: 'Faqs',
                routerLink: '/faqs',
            },
            {
                id: 'about',
                label: 'About',
                routerLink: '/about',
            },
        ]
    }

    override getUserMenuItems(): MenuItem[] {
        return [
            this.appSections.profile,
            this.appSections.privacyPolicy,
            this.appSections.logout,
        ];
    }

    override getViewerToolbarTools() {
        let tools = super.getViewerToolbarTools();
        tools = tools.filter(t => t.id !== this.viewerToolbarTools.relatedGuides.id);
        const courseDetails: ViewerToolbarTool = {
            id: 'courseDetails',
            icon: 'school-course',
            label: 'Course details',
            isToggler: true,
            limitToContentType: 'course',
            panelComponent: { component: ViewerCourseDetailComponent, },
        };
        return [courseDetails, ...tools];
    }

    override getViewerToolbarSecondaryTools() {
        const faq: ViewerToolbarTool = {
            id: 'faq',
            icon: 'help-circle',
            label: 'FAQ',
            isToggler: true,
            panelComponent: { component: FaqsListComponent, },
        };
        return [
            faq,
            this.viewerToolbarTools.credits,
            this.viewerToolbarTools.home,
        ]
    }
}