import { Injectable } from '@angular/core';

import { User } from 'src/index';
import { AuthenticationService, clone } from 'src/index/services.index';

const BASE_STRUCTURE_JSON: LiquidViewerJsonStructure = {
  "template": "<div id=\"container\">\n    <h1>\n    <element id=\"pageTitle\"></element>\n</h1>  \n</div>",
  "css": "#container\n{\n    margin: 0 10px;\n    width: 100%;\n    font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;\n    font-size: 17px;\n}\nh1\n{\n    font-size: 25px;\n    line-height: 1.2;\n}\nh2\n{\n    font-size: 20px;\n    line-height: 1.5;\n}\nh2:before\n{\n    background-color: #E3AC3D;\n    content: '';\n    display: inline-block;\n    height: 15px;\n    margin-right: 10px;\n    width: 15px;\n}\nh3,h4\n{\n    font-size: 15px;\n    line-height: 1.5;\n}\nh3:before\n{\n    background-color: #E3AC3D;\n    content: '';\n    display: inline-block;\n    height: 15px;\n    margin-right: 10px;\n    width: 15px;\n}\nh4:before\n{\n    background-color: #E3AC3D;\n    content: '';\n    display: inline-block;\n    height: 15px;\n    width: 15px;\n}\na:link, a:visited, a:hover, a:active\n{\n    background-color: transparent;\n    color: #F58529;\n    text-decoration: underline;\n}\n.mjx-chtml\n{\n    font-size: 100%!important;\n}\n#immagine\n{\n    text-align: center;\n}\n.image img\n{\n    height: auto;\n    max-width: 90%;\n}\n.image {\n    text-align: center;\n    padding: 20px 0;\n}\n.graph {\n    padding: 20px;\n}\n.video, .slideshow {\n    padding: 20px 0;\n}\np,dt,dd,div.text\n{\n    font-size: 17px;\n    line-height: 2;\n    margin-right: 3%;\n}\ndl,ul,ol\n{\n    font-size: 17px;\n    line-height: 2;\n    margin-left: 10px;\n}\n\n",
  "contents": {
    "pageTitle": {
      "type": "text",
      "value": "Pagina senza nome"
    },
  },
}

@Injectable({
  providedIn: 'root'
})
export class ViewerContentService {

  constructor(
    public authenticationService: AuthenticationService<User>,
  ) { }

  handleData(data: any) {

    const token = this.authenticationService.getToken();

    if (data.styles) {
      data.styles = `${data.styles}?token-session=${token}`;
    }

    return data;
  }

  convert(content: any, contentType: string, tokenSession: string, serverRootApi?: string) {

    if (!content.template) {
      content = this.createTemplateField(content, contentType, tokenSession, serverRootApi);
    }

    content = this.convertTemplateField(content);
    return content;
  }

  private convertTemplateField(content: any) {

    const templateString = content.template.replace(/(>) (<[^\/]*?>)/gi, (match: any, p1: string, p2: string) => {
      if (p2.indexOf('<p') < 0 && p2.indexOf('<div') < 0 && p2.indexOf('<section') < 0) {
        return p1 + '&nbsp;' + p2;
      }
      return match;
    });

    const tempElement = document.createElement('template');
    tempElement.innerHTML = templateString.trim();
    const templateElement = tempElement.content.firstChild as HTMLElement;

    if (templateElement.querySelectorAll('element')) {

      templateElement.querySelectorAll('element').forEach((el, _) => {

        if (content.contents[el.id]) {

          switch (content.contents[el.id].type) {
            case 'text':
              el.outerHTML = `<span>${content.contents[el.id].value}</span>`;
              break;
            case 'pdf':
              el.outerHTML = `<meta-pdf [pdf]="contents['${el.id}']"></meta-pdf>`;
              break;
          }
        }
      });

    }

    content.template = templateElement.outerHTML;

    return content;
  }

  private createTemplateField(content: any, contentType: string, tokenSession: string, serverRootApi?: string) {
    let convertedContent = clone(BASE_STRUCTURE_JSON);

    if (content.title) {
      convertedContent.contents.pageTitle.value = content.title;
    }

    if (content.id) {
      convertedContent.id = content.id;
    }

    if (contentType) {
      convertedContent = this.createElement(convertedContent, content, contentType, tokenSession, serverRootApi);
    }
    convertedContent.contentType = contentType;
    return convertedContent;
  }

  private createElement(convertedContent: any, originalContent: any, contentType: string, tokenSession: string, serverRootApi?: string) {

    if (['exercise', 'survey'].indexOf(contentType) !== -1) {
      return this.createExerciseElement(convertedContent, originalContent, serverRootApi);
    }

    if (['video_text', 'video'].indexOf(contentType) !== -1) {
      return this.createVideoTextElement(convertedContent, originalContent, tokenSession);
    }

    const newContentId = contentType + '-' + parseInt(`${Math.random() * 10000}`);

    // Modifico il campo "template"
    const templateString = convertedContent.template.replace(/(>) (<[^\/]*?>)/gi, (match: any, p1: string, p2: string) => {
      if (p2.indexOf('<p') < 0 && p2.indexOf('<div') < 0 && p2.indexOf('<section') < 0) {
        return p1 + '&nbsp;' + p2;
      }
      return match;
    });

    const templateElement = document.createElement('template');
    templateElement.innerHTML = templateString.trim();

    const newElement = document.createElement('element');
    newElement.setAttribute('id', newContentId);

    if (templateElement.content && templateElement.content.firstChild) {
      templateElement.content.firstChild.appendChild(newElement);
    }

    convertedContent.template = templateElement.innerHTML;

    // Creo il nuovo item in "contents"
    let newContentsItem: ContentsItem = {
      id: newContentId,
      type: contentType
    }

    switch (contentType) {
      case 'pdf':
        newContentsItem.value = `${originalContent.file}?token-session=${tokenSession}`;
        newContentsItem.downloadable = originalContent.downloadable == true;
        break;
      case 'slide':
        newContentsItem.type = "gallery";
        newContentsItem.values = [];

        originalContent.entries.forEach((e: any, _: number) => {
          newContentsItem.values.push({
            src: `${e.image}?token-session=${tokenSession}`,
            thumb: `${e.thumbnail}?token-session=${tokenSession}`
          })
        })
        break;
    }

    convertedContent.contents[newContentId] = newContentsItem;

    return convertedContent;
  }

  private createVideoTextElement(convertedContent: any, originalContent: any, tokenSession: string) {

    // Modifico il campo "template"
    const templateString = convertedContent.template.replace(/(>) (<[^\/]*?>)/gi, (match: any, p1: string, p2: string) => {
      if (p2.indexOf('<p') < 0 && p2.indexOf('<div') < 0 && p2.indexOf('<section') < 0) {
        return p1 + '&nbsp;' + p2;
      }
      return match;
    });

    const templateElement = document.createElement('template');
    templateElement.innerHTML = templateString.trim();

    // Video
    const videoContentId = 'video-' + parseInt(`${Math.random() * 10000}`);
    const videoElement = document.createElement('element');
    videoElement.setAttribute('id', videoContentId);

    if (templateElement.content && templateElement.content.firstChild) {
      templateElement.content.firstChild.appendChild(videoElement);
    }
    convertedContent.template = templateElement.innerHTML;

    let videoContentsItem: ContentsItem = {
      id: videoContentId,
      type: 'video',
      value: `${originalContent.file}?token-session=${tokenSession}`,
      scenes: originalContent.scenes && originalContent.scenes.length > 0 ? originalContent.scenes : [],
    }

    convertedContent.contents[videoContentId] = videoContentsItem;

    // Testo
    if (originalContent.text && originalContent.text.length > 0) {
      const textContentId = 'text-' + parseInt(`${Math.random() * 10000}`);
      const textElement = document.createElement('element');
      textElement.setAttribute('id', textContentId);

      if (templateElement.content && templateElement.content.firstChild) {
        templateElement.content.firstChild.appendChild(textElement);
      }
      convertedContent.template = templateElement.innerHTML;

      let textContentsItem: ContentsItem = {
        id: textContentId,
        type: 'text',
        value: `${originalContent.text}`,
      }

      convertedContent.contents[textContentId] = textContentsItem;
    }

    return convertedContent;
  }

  private createExerciseElement(convertedContent: any, originalContent: any, serverRootApi?: string) {

    // Modifico il campo "template"
    const templateString = convertedContent.template.replace(/(>) (<[^\/]*?>)/gi, (match: any, p1: string, p2: string) => {
      if (p2.indexOf('<p') < 0 && p2.indexOf('<div') < 0 && p2.indexOf('<section') < 0) {
        return p1 + '&nbsp;' + p2;
      }
      return match;
    });

    const templateElement = document.createElement('template');
    templateElement.innerHTML = templateString.trim();

    originalContent.questions.forEach((ex: any, _: number) => {

      const exerciseId = ex.id;

      const newElement = document.createElement('element');
      newElement.setAttribute('id', exerciseId);

      if (templateElement.content && templateElement.content.firstChild) {
        templateElement.content.firstChild.appendChild(newElement);
      }
      convertedContent.template = templateElement.innerHTML;

      let exLabels = [];
      for (let i = 1; i <= 5; i++) {
        if (ex[`label_${i}`]) {
          exLabels.push(ex[`label_${i}`]);
        }
      }

      if (ex.answers) {
        ex.answers.forEach((answer: any, indexAnswer: number) => {
          let correctIndex = -1;
          for (let i = 1; i <= 5; i++) {
            if (answer[`correct_${i}`]) {
              correctIndex = i - 1;
            }

            delete ex.answers[indexAnswer][`correct_${i}`];
          }

          ex.answers[indexAnswer].correct = answer.correct ? answer.correct : (
            correctIndex > -1 ? correctIndex : false
          );
        })
      }

      const question = typeof ex.question === 'string' ? { text: ex.question || '' } : ex.question || {};

      if (ex.questionMedia) {
        const splitted = ex.questionMedia.split('.');

        let questionMediaType;
        switch (splitted[splitted.length - 1]) {
          case 'jpg':
            questionMediaType = 'image';
            break;
          case 'mp4':
            questionMediaType = 'video';
            break;
          case 'pdf':
            questionMediaType = 'pdf';
            break;
        }

        question.media = {
          id: undefined,
          type: questionMediaType,
          src: ex.questionMedia.replace('/opt/data/export', `${serverRootApi}/content/export`),
          title: undefined,
        }
      }

      let newContentsItem: ContentsItem = {
        id: exerciseId,
        type: 'exercise',
        value: ex.type.replace('Question_', ''),
        noFeedbackMode: ex.noFeedbackMode || false,
        hideSolutions: ex.hideSolutions || false,
        immediateConfirm: ex.immediateConfirm || false,
        mandatory: ex.mandatory || false,
        data: {
          id: exerciseId,
          type: ex.type.replace('Question_', ''),
          difficulty: ex.difficulty,
          labels: exLabels,
          answers: ex.answers || [],
          question: question,
          questionMedia: ex.questionMedia ? ex.questionMedia : undefined,
          start: ex.start || undefined,
          end: ex.end || undefined,
          startLabel: ex.startLabel || undefined,
          endLabel: ex.endLabel || undefined,
          multipleChoice: ex.multipleChoice || false,
        }
      }

      if (ex.feedback && (ex.feedback.text || ex.feedback.media)) {
        newContentsItem.data.feedback = {
          text: ex.feedback.text || undefined,
          media: ex.feedback.media || undefined,
        }
      }

      if (ex.visibilityRule) {
        newContentsItem.data.visibilityRule = ex.visibilityRule;
      }

      if (ex.relatedExercises) {
        newContentsItem.data.relatedExercises = ex.relatedExercises;
      }

      convertedContent.contents[exerciseId] = newContentsItem;

    })

    return convertedContent;
  }
}

interface ContentsItem {
  id: string,
  type: string,
  value?: any,
  values?: any, // campo usato solo da Slideshow
  data?: any, // campo usato solo da Exercise
  noFeedbackMode?: boolean, // campo usato solo da Exercise
  hideSolutions?: boolean, // campo usato solo da Exercise
  immediateConfirm?: boolean, // campo usato solo da Exercise
  mandatory?: boolean, // campo usato solo da Exercise
  scenes?: any, // campo usato solo da Video
  downloadable?: boolean, // campo usato solo da PDF
}

interface LiquidViewerJsonStructure {
  id?: string;
  template: string;
  css: string;
  contents: {
    pageTitle: {
      type: string;
      value: string;
    };
  };
  contentType?: string;
}